<template>
  <div class="form">
    <el-form class="content " ref="form" :model="form" label-width="150px">
      <el-form-item  class="itemTop" label="销售订单号" prop="sellOrderCode" :rules="{ required: true, message: '请输入销售订单号', trigger: 'blur' }">
        <el-input v-model="form.sellOrderCode" style="width:70%"></el-input>
      </el-form-item>
      <el-form-item label="销售员" prop="sellman" :rules="{ required: true, message: '请输入销售员', trigger: 'blur' }">
        <el-input v-model="form.sellman" style="width:70%"></el-input>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>

export default {
  name: 'OrderdetailDialog',
  props: ['showData'],
  data () {
    var formdata = {}
    if (this.showData) {
      formdata = this.showData
    }
    return {
      form: formdata
    }
  }
}
</script>
<style scoped lang="scss">
@import '@/assets/css/elform.scss';
  .form{
   .itemTop{
  margin-bottom: 20px;
  }
  }

</style>
